.content {
    /* justify-content: center; */
    /* align-self: center; */
    
    border: black 1px solid;

    margin: 5%;
}

/* for all of the background divs */
#root, .main-body{
    background-color: whitesmoke
}

/* .horizontalSpace{
    padding: 100px;
}

.verticalSpace{
    padding: 100px;
} */



.my-card:hover{
    transform: translateY(-10px);
    text-decoration: none;
}