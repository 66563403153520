
.card-top{
    display: flex;
    text-align: center;
    padding-top: 3%;
    padding-bottom: 3%;
    height: 50%;
}

.landing-left{
    width: 50%;
    border-right: solid black 1px;

}

.landing-external-links{
    display: flex;
    text-align: center;
    height: 100%;
}

.landing-right{
    width: 50%;
}

.scrolling-project-container{
    width: 100%;
    padding: 5%;
    height: 80%;
    /* overflow-y: auto; */

    display: flex;
    justify-content: center;
    align-items: center;
}

.project-nav-buttons{
    display: flex;
    align-items: center;
}

.nav-right{
    margin-left: auto;
}

.nav-left{
    margin-right: auto;
}

.card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.resource-link{
    text-decoration: none;
    color: black;
    font-weight: 500;
}

.my-card-landing{
    margin: 5%;
    padding: 5%;
    
    width: 50%;

    border: solid  #109037 5px;
    background-color:  #a4e7b8; 

    transition: transform 0.3s ease 0s;
}

.my-card-landing:hover{
    transform: translateY(-10px);
}