/* Main Card Bottom */
.card-bottom{
    display: flex;
    text-align: center;
    justify-content: space-between;
    /* padding-left: 3%;
    padding-right: 3%; */
    padding: 3%;
    height: 50%;
}

.test{
    border: solid black 1px;
    width: 47%;
    
}

.words{
    height: 100%;
    display: flex;
    /* flex: auto; */
    flex-direction: column;
    justify-content: space-between;
    
}


/* -------------------------------- */

/* Icon Display */
.single-icon{
    width: 100px;
    height: 100px;
}

.all-tech-icons{
    display: flex;
    justify-content: space-evenly;
}