.singleProjectBlock{
    text-decoration: none;
    width: 92%;
}

.singleProjectBlock:hover{
    text-decoration: none;
}

.card-title{
    text-decoration: none;
    color: black;
    margin-left: 0;
    margin-right:auto;
}

.project-blurb{
    margin: auto;
    text-align: right;
    color:  black;
    font-weight: 500;
}

.my-card{
    width: 80%;
    height: 80%;

    display: flex;
    justify-content: center;
    align-items: center;

    border: solid  #109037 5px;
    background-color:  #a4e7b8; 

    transition: transform 0.3s ease 0s;
}

/* @media only screen and (max-width: 767px){

    .card-info{
        text-align: center;
    }

    .card-title{
        margin-left: auto;
        margin-right: auto;
    }

    .project-blurb{
        text-align: center;
    }
} */
